<template>
  <v-container>
    <v-row justify="center">
      <v-col
        cols="12"
        xs="12"
        class="mt-0"
      >
        <v-text-field
          v-if="mode === EDIT"
          v-model="userData.user_id"
          prepend-icon="mdi-card-account-details"
          label="User ID"
          type="text"
          disabled
        />
        <v-text-field
          v-model="userData.login"
          prepend-icon="mdi-login-variant"
          label="Login / Username"
          type="text"
        />
        <v-text-field
          v-model="userData.email"
          prepend-icon="mdi-email"
          label="Email Address"
          type="text"
        />
        <v-text-field
          v-model="userData.tempPassword"
          prepend-icon="mdi-lock"
          label="Temporary Password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
        <v-select
          v-model="userData.user_type"
          :items="USER_TYPES"
          item-text="name"
          item-value="value"
          :value="userData.user_type"
          prepend-icon="mdi-account"
          label="User Type"
        />
        <v-select
          v-model="userData.user_status_code"
          :items="USER_STATUSES"
          item-text="name"
          item-value="value"
          :value="userData.user_status_code"
          prepend-icon="mdi-account"
          label="User Status"
        />
        <v-select
          v-if="showLocationAccess"          
          :value="userData.primary_location_id"
          :items="locations"
          item-text="name"
          item-value="primary_location_id"
          attach
          chips
          clearable
          label="Primary Location"
          prepend-icon="mdi-warehouse"
          @change="primaryLocationChange"
        />
        <v-select
          v-if="showLocationAccess"
          :v-model="userData.locations"
          :value="userData.locations"
          :items="locations"
          item-text="name"
          item-value="location_id"
          attach
          chips
          clearable
          label="Location Access"
          prepend-icon="mdi-warehouse"
          multiple
        />
        <v-select
          v-if="showSupplierAccess"
          :value="userData.primary_supplier_id"
          :items="suppliers"
          item-text="name"
          item-value="supplier_id"
          attach
          chips
          clearable
          label="Primary Supplier"
          prepend-icon="mdi-domain"
        />
        <v-select
          v-if="showSupplierAccess"
          :value="userData.suppliers"
          :items="suppliers"
          item-text="name"
          item-value="supplier_id"
          attach
          chips
          clearable
          label="Supplier Access"
          prepend-icon="mdi-domain"
          multiple
        />
        <v-text-field
          v-if="mode === EDIT"
          :value="Date(userData.modifiedDate)"
          prepend-icon="mdi-calendar-today"
          label="Last Updated Date"
          type="text"
          disabled
        />
        <v-text-field
          v-if="mode === EDIT"
          :value="Date(userData.createdDate)"
          prepend-icon="mdi-calendar"
          label="Created Date"
          type="text"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        class="pa-2 sl-button"
        @click.native="clearForm()"
      >
        Clear Form 
      </v-btn>
      <v-btn
        class="pa-2 sl-button"
        @click.native="createUser()"
      >
        Create User
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { EDIT, CREATE, ADMIN, LOCATION, SUPPLIER, USER_TYPES, USER_STATUSES } from '@/static';
import { getSuppliers, getLocations, createUser } from '../../api/api.service';

export default {
  name: 'CreateUser',
  props: {
    mode: { type: String, default: () => '' },
    user: { type: Object, default: () => {} }
  },
  data: () => ({ 
    userData: null,
    showPassword: false,
    suppliers: [],
    locations: [],
    EDIT,
    CREATE,
    LOCATION, 
    SUPPLIER, 
    USER_TYPES, 
    USER_STATUSES
  }),
  computed: {
    showLocationAccess() {
      return this.userData?.user_type === ADMIN || this.userData?.user_type === LOCATION;
    },
    showSupplierAccess() {
      return this.userData?.user_type === ADMIN || this.userData?.user_type === SUPPLIER;
    }
  },
  watch: {
    showSupplierAccess(newVal) {
      if (newVal && this.suppliers.length === 0) {
        getSuppliers().then(suppliers => {
          this.suppliers = suppliers;
        });
      }
    },
    showLocationAccess(newVal) {
      if (newVal && this.locations.length === 0) {
        getLocations().then(locations => {
          this.locations = locations;
        });
      }
    }
  },
  async created() {
    if (this.mode === CREATE) { 
      this.userData = this.getDefaultUser();
    } else if (this.mode === EDIT) {
      this.userData = {...this.user};
    }
    const [ locations, suppliers ] = await Promise.all([ 
      getLocations(this.$store), 
      getSuppliers(this.$store) 
    ]);
    this.locations = locations;
    this.suppliers = suppliers;
  },
  methods: {
    getDefaultUser() {
      return {
        login: '',
        username: '',
        email: '',
        password: '',
        user_type: null,
        user_status_code: null,
        locationAccess: null,
        supplierAccess: null,
        primary_location_id: null,
        locations: [],
        primary_supplier_id: [],
        suppliers: []
      };
    },
    primaryLocationChange(newVal) {
      this.userData.primary_location_id = newVal;
    },
    clearForm() {
      this.userData = this.getDefaultUser();
    },
    async createUser() {
      this.submitting = true;
      let response;
      try {
        response = await createUser(this.$store, this.userData);
        this.$notify({
          message: 'User Successfully Created',
          icon: 'fact_check',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
      } catch (err) {
        console.error(err);
        this.$notify({
          message:
            `Error, ${response.error}`,
          icon: 'add_alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
