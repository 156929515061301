<template>
  <div
    class="wrapper"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <notifications />

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content" />
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>
      <sidebar-link to="/shipments">
        <md-icon>local_shipping</md-icon>
        <p>My Shipments</p>
      </sidebar-link>
      <sidebar-link to="/find-shipment">
        <md-icon>search</md-icon>
        <p>Find Shipment</p>
      </sidebar-link>
      <sidebar-link
        v-if="canCreateShipments"
        to="/create-shipment"
      >
        <md-icon>inventory</md-icon>
        <p>Create Shipment</p>
      </sidebar-link>
      
      <sidebar-link
        v-if="isAdmin"
        to="/admin"
      >
        <md-icon>manage_accounts</md-icon>
        <p>Admin</p>
      </sidebar-link>
      <sidebar-link
        to="/logout"
        class="logout"
      >
        <md-icon>person</md-icon>
        <p>Logout</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar />

      <dashboard-content />

      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './PageContent.vue';
import MobileMenu from '@/pages/Layout/MobileMenu.vue';
import notifications from '../../components/NotificationPlugin/Notifications';
import { ADMIN, SUPPLIER } from '@/static/constants';

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    notifications
  },
  data () {
    return {
      sidebarBackground: 'green',
      sidebarBackgroundImage: require('@/assets/img/sidebar-2.jpg')
    };
  },
  computed: {
    ...mapGetters([ 'user' ]),
    isAdmin () {
      return this.user?.userType === ADMIN;
    },
    canCreateShipments () {
      return this.user?.userType === ADMIN || this.user?.userType === SUPPLIER;
    }
  },
  methods: {}
};
</script>
