export const ADMIN = 'SLX';
export const LOCATION = 'LOC';
export const SUPPLIER = 'SUP';

export const shipmentStatus = {
  CREATED: 'CREATED',
  CANCELLED: 'CANCELLED',
  PARTIAL_RECEIVED: 'PARTIAL_RECEIVED',
  RECEIVED: 'RECEIVED',
  PARTIAL_COLLECTION: 'PARTIAL_COLLECTION',
  COLLECTED: 'COLLECTED'
};

export const DELIVERY = 'Delivery';
export const COLLECTION = 'Collection';
export const CANCEL = 'Cancellation';
