export const LOCATION = 'LOC';
export const SUPPLIER = 'SUP';
export const ADMIN = 'SLX';

export const USER_TYPE = {
  SLX: 'Admin',
  LOC: 'Location',
  SUP: 'Supplier' 
};

export const USER_TYPES = [ 
  { value: LOCATION, name: USER_TYPE.LOC }, 
  { value: ADMIN, name: USER_TYPE.SLX }, 
  { value: SUPPLIER, name: USER_TYPE.SUP }
];

export const A = 'A';
export const I = 'I';
export const S = 'S';

export const USER_STATUS = {
  A: 'Active',
  I: 'Inactive',
  S: 'Suspended'
};

export const USER_STATUSES = [ 
  { value: A, name: USER_STATUS.A }, 
  { value: I, name: USER_STATUS.I }, 
  { value: S, name: USER_STATUS.S }
];


export const COLLECTED = 'COLLECTED';
export const CREATED = 'CREATED';
export const RECEIVED = 'RECEIVED';
export const PARTIAL_RECEIVED = 'PARTIAL_RECEIVED';
export const CANCELLED = 'CANCELLED';
export const IN_TRANSIT = 'IN_TRANSIT';
export const PARTIAL_COLLECTION = 'PARTIAL_COLLECTION';

export const shipmentStatus = {
  CREATED: 'CREATED',
  CANCELLED: 'CANCELLED',
  IN_TRANSIT: 'IN_TRANSIT',
  PARTIAL_RECEIVED: 'PARTIAL_RECEIVED',
  RECEIVED: 'RECEIVED',
  PARTIAL_COLLECTION: 'PARTIAL_COLLECTION',
  COLLECTED: 'COLLECTED'
};

export const supplierView = 'Supplier';
export const locationView = 'Location';


export const supplierHeaders = (mode) => {
  return [
    { text: 'Location Name', value: 'location_business_name' },
    { text: 'Collection Name', value: 'CollectionName' },
    { text: `${mode}`, value: 'selectDate' },
    { text: 'Order No', value: 'SupplierOrderNo' },
    { text: 'Cust Ref', value: 'CustomerOrderRef' },
    { text: 'Order Qty', value: 'qtyDesc' },
    { text: 'Searchable Date', value: 'searchDate', align: ' d-none' }
  ];
};
export const locationHeaders = (mode) => { 
  return [
    { text: 'Supplier Name', value: 'supplier_name' },
    { text: 'Collection Name', value: 'CollectionName' },
    { text: `${mode}`, value: 'selectDate' },
    { text: 'Order No', value: 'SupplierOrderNo' },
    { text: 'Cust Ref', value: 'CustomerOrderRef' },
    { text: 'Order Qty', value: 'qtyDesc' },
    { text: 'Searchable Date', value: 'searchDate', align: ' d-none' }
  ];
};

export const SHIPMENT_ITEM_HEADERS = [
  { text: 'Barcode', value: 'barcode' },
  { text: 'Supplier Ref', value: 'SupplierOrderItemRef' },
  { text: 'Status', value: 'status' },
  { text: 'Dimension', value: 'dimension' },
  { text: 'POD', value: 'pod' }
];

export const CREATE = 'Create';
export const EDIT = 'Edit';
