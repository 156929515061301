<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <a
        data-toggle="dropdown"
        class="dropdown-toggle"
      >
        <p class="ml-auto">
          {{ user }}
        </p>
        <i class="material-icons">person</i>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  computed: {
    user() {
      return localStorage.getItem('userInfo');
    }
  }
};
</script>
