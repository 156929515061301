<template>
  <div style="width:100%; height:100%;">
    <notifications />
    <div
      class="d-flex flex-column mt-15 align-center"
      style="width:100%; height:100%;"
    > 
      <div class="logo-img mb-6">
        <img
          :src="imgLogo"
          alt=""
          style="max-height: 130px;"
        >
      </div>
      <md-card style="max-width:800px;">
        <md-card-header data-background-color="green">
          <h4 class="title">
            Login
          </h4>
        </md-card-header>
        <md-card-content>
          <v-text-field
            v-model="username"
            :rules="[rules.required]"
            label="Username"
            class="mx-5"
            outlined
          />
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.required]"
            label="Password"
            class="mx-5"
            outlined
            @keyup.enter="login"
            @click:append="showPassword = !showPassword"
          />
          <v-row
            align="center"
            justify="space-around"
          >
            <v-btn
              :disabled="!username || !password"
              :loading="loggingIn"
              large
              class="sl-button mb-3"
              align="center"
              style="min-width:150px;"
              @click="login"
            >
              Login
            </v-btn>
          </v-row>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { logInWithEmailAndPassword } from '../firebase/firebase';
import notifications from '../components/NotificationPlugin/Notifications';
import { getUserInfo } from '../api/api.service';

export default {
  name: 'LoginPage',
  components: {
    notifications
  },
  props: {
    imgLogo: {
      type: String,
      default: require('@/assets/img/logo.png')
    }
  },
  data () {
    return {
      username: null,
      password: null,
      loggingIn: false,
      showPassword: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    };
  },
  methods: {
    async login () {
      this.loggingIn = true;
      const loginResponse = await logInWithEmailAndPassword(this.username, this.password);
      this.loggingIn = false;
      if (loginResponse.error) {
        this.$notify({
          message:
            'Invalid Username / Password. Please try again, if the problem persists please contact your admin.',
          icon: 'add_alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      } else {
        const userInfo = await getUserInfo(this.$store);
        localStorage.setItem('userInfo', `${userInfo.display_name} (${userInfo.userLocation})`);
        this.$store.dispatch('GET_USER', loginResponse);
        this.$router.push('/dashboard');
      }
    }
  }
};
</script>
