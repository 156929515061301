import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardLayout from '@/pages/Layout/DashboardLayout.vue';

import Dashboard from '@/pages/Dashboard.vue';
import UserProfile from '@/pages/UserProfile.vue';
import TableList from '@/pages/TableList.vue';
import Typography from '@/pages/Typography.vue';
import Icons from '@/pages/Icons.vue';
import Notifications from '@/pages/Notifications.vue';
import Login from '@/pages/Login.vue';
import FindShipment from '@/pages/FindShipment.vue';
import Shipment from '@/pages/Shipment.vue';
import Shipments from '@/pages/Shipments.vue';
import CreateShipment from '@/pages/CreateShipment.vue';
import AdminPanel from '@/pages/AdminPanel.vue';
import { logout, refresh } from '../firebase/firebase';

const isAuthenticated = async (to, from, next) => {
  if (!Vue.$cookies.get('accessToken') && !Vue.$cookies.get('refreshToken')) {
    router.push('/login');
    next();
  } else if (Vue.$cookies.get('refreshToken') && !Vue.$cookies.get('accessToken')) {
    await refresh();
  }
  next();
};

const isLoggedIn = async (to, from, next) => {
  if (Vue.$cookies.get('accessToken') && Vue.$cookies.get('refreshToken')) {
    router.push('/dashboard');
    next();
  } else if (Vue.$cookies.get('refreshToken') && !Vue.$cookies.get('accessToken')) {
    await refresh();
  }
  next();
};

const logMeOut = () => {
  logout();
  router.go();
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: isAuthenticated
      },
      {
        path: 'user',
        name: 'User Profile',
        component: UserProfile,
        beforeEnter: isAuthenticated
      },
      {
        path: 'table',
        name: 'Table List',
        component: TableList,
        beforeEnter: isAuthenticated
      },
      {
        path: 'typography',
        name: 'Typography',
        component: Typography,
        beforeEnter: isAuthenticated
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons,
        beforeEnter: isAuthenticated
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'find-shipment',
        name: 'Find Shipment',
        component: FindShipment,
        beforeEnter: isAuthenticated
      },
      {
        path: 'shipment/:id',
        name: 'Shipment',
        component: Shipment,
        beforeEnter: isAuthenticated,
        props: true
      },
      {
        path: 'shipments',
        name: 'Shipments',
        component: Shipments,
        beforeEnter: isAuthenticated,
        props: true
      },
      {
        path: 'create-shipment',
        name: 'Create Shipment',
        component: CreateShipment,
        beforeEnter: isAuthenticated,
        props: true
      },
      {
        path: 'admin',
        name: 'Admin',
        component: AdminPanel,
        beforeEnter: isAuthenticated,
        props: true
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: isLoggedIn
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: logMeOut
  }
];

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: 'nav-item active'
});

export default router;
