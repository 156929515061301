<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">
              Find a shipment
            </h4>
            <p class="category">
              Type search terms into the field below
            </p>
          </md-card-header>
          <md-card-content>
            <v-container>
              <v-row>
                <v-col
                  class="d-flex justify-start align-end"
                  sm="12"
                  md="10"
                >
                  <v-text-field
                    v-model="searchTerm"
                    label="Search"
                    :rules="rules"
                    hide-details="auto"
                    prepend-icon="mdi-magnify"
                    class="search-input"
                    :loading="searching"
                    @keyup.enter="searchForShipment"
                  />
                </v-col>
                <v-col
                  class="d-flex justify-end align-center"
                  sm="2"
                >
                  <v-btn
                    :disabled="!searchTerm"
                    :loading="searching"
                    class="search-btn"
                    @click="searchForShipment"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-card class="mt-2">
              <v-card-title class="table-header">
                Shipments
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  style="max-width: 250px"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="found"
                :search="search"
                :loading="searching"
                loading-text="Loading... Please wait"
                @click:row="goToShipment"
              />
            </v-card>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShipmentsSearchTerm } from '../api/api.service';
import { checkUser } from '../service/service';

export default {
  name: 'FindShipment',
  props: {
    dataBackgroundColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      rules: [
        value => !!value || 'Required.'
      ],
      searchTerm: '',
      searching: false,
      found: [],
      search: '',
      headers: [
        { text: 'Supplier Name', value: 'supplier_name' },
        { text: 'Location Name', value: 'location_business_name' },
        { text: 'Collection Name', value: 'CollectionName' },
        { text: 'Status', value: 'shipment_item_StatusCode' },
        { text: 'Order No', value: 'SupplierOrderNo' },
        { text: 'Cust Ref', value: 'CustomerOrderRef' }
      ]
    };
  },
  computed: {
    ...mapGetters([ 'user' ])
  },
  async created () {
    await checkUser(this.user, this.$store, this.$router);
  },
  methods: {
    async searchForShipment () {
      this.searching = true;
      try {
        this.found = await getShipmentsSearchTerm(this.$store, this.searchTerm);
      } catch (err) {
        console.err(err);
      } finally {
        this.searching = false;
      }
    },
    goToShipment(shipment) {
      this.$router.push({ name: 'Shipment', params: { id: shipment.shipment_id, shipmentTab: -1  }});
    }
  }
};
</script>
