<template>
  <div class="pa-3">
    <v-card class="mt-2">
      <v-card-title class="table-header">
        Users
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="max-width: 250px"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loadingUsers"
        loading-text="Loading... Please wait"
        @click:row="openUser"
      />
    </v-card>
    <UserModal
      :show="showUserModal"
      :selected-user="selectedUser"
      :locations="locations"
      :suppliers="suppliers"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADMIN, LOCATION, SUPPLIER, USER_TYPES, USER_STATUSES} from '@/static';
import { getUsers, getLocations, getSuppliers } from '../../api/api.service';
import { getUserType } from '../../service/service';
import { UserModal } from '@/components';

export default {
  name: 'AdminUsers',
  components: {
    UserModal
  },
  data () {
    return {
      search: '',
      users: [],
      loadingUsers: true,
      locations: [],
      suppliers: [],
      LOCATION: LOCATION,
      SUPPLIER: SUPPLIER,
      USER_TYPES: USER_TYPES, 
      USER_STATUSES: USER_STATUSES,
      showUserModal: false,
      selectedUser: null,
      headers: [
        { text: 'User ID', value: 'user_id' },
        { text: 'User', value: 'login' },
        { text: 'User Type', value: 'user_type' },
        { text: 'User Status', value: 'user_status_code' }
      ]
    };
  },
  computed: {
    ...mapGetters([ 'user' ])
  },
  watch: {
    search(searchValue) {
      if (searchValue) {
        this.filteredUsers = this.users.filter(user => user.login.includes(searchValue));
      } else {
        this.filteredUsers = this.users;
      }
    }
  }, 
  async created() {
    if (this.user.userType !== ADMIN) {
      this.$router.push('/dashboard');
    }
    const [ users, locations, suppliers ] = await Promise.all([ 
      getUsers(this.$store), 
      getLocations(this.$store), 
      getSuppliers(this.$store) 
    ]);
    this.users = users;
    this.locations = locations;
    this.suppliers = suppliers;
    this.filteredUsers = this.users;
    this.loadingUsers = false;
  },
  methods: {
    getUserType,
    clearSearch () {
      this.search = '';
    },
    async openUser(user) {
      this.selectedUser = user;
      this.showUserModal = true;
    },
    close() {
      this.showUserModal = false;
    }
  }
};
</script>
