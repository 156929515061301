<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <form>
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">
                Create Shipment
              </h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-select
                    :items="suppliers"
                    label="Supplier"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-select
                    :items="locations"
                    label="Location"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <v-text-field
                    label="Barcode"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <v-text-field
                    label="Shipment Type"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-33">
                  <v-text-field
                    label="Number Of Items"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-text-field
                    label="Description"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-text-field
                    label="Customer Pickup Ref"
                  />
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="End Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="md-layout-item md-size-100">
                  <v-textarea>
                    <template #label>
                      <div>
                        Shipment Description
                      </div>
                    </template>
                  </v-textarea>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <v-btn  
                    class="sl-button mb-3"
                    align="center"
                    style="min-width:150px;"
                  >
                    Create Shipment
                  </v-btn>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateShipmentForm',
  data () {
    return {
      locations: [ 'Location 1', 'Location 2', 'Loction 3' ],
      suppliers: [ 'Supplier 1' ]
    };
  }
};
</script>
<style></style>
