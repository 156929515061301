<template>
  <div class="content">
    <div
      v-if="loading"
      class="md-layout justify-center"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      />
    </div>
    <div
      v-else
      class="md-layout"
    >
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">
              <v-icon color="white">
                inventory
              </v-icon>
              {{ shipment.supplier_name }} - {{ shipment.barcode }} - ({{ shipment.description }})
            </h4>
            <p class="category">
              {{ shipment.CollectionName }} <br>
              {{ shipment.CollectionNameAddnl }}
            </p>
          </md-card-header>
          <md-card-content>
            <div>
              <div
                class="flex-justify-content-center"
              >
                <v-btn
                  class="pa-2"
                  @click.native="goBack"
                >
                  <v-icon>arrow_back</v-icon> Go Back
                </v-btn>
                <v-btn
                  v-if="showAcceptDelivery"
                  class="pa-2 sl-button"
                  @click.native="showShipmentItemProgressModel(DELIVERERY)"
                >
                  Accept Delivery
                </v-btn>
                <v-btn
                  v-if="showAcceptCollection"
                  class="pa-2 sl-button"
                  @click.native="showShipmentItemProgressModel(COLLECTION)"
                >
                  Confirm Collection
                </v-btn>
                <v-btn
                  v-if="showCancelShipment"
                  class="pa-2"
                  color="error"
                  :disabled="user.userType === LOCATION || shipment.status === COLLECTED || shipment.status === CANCELLED"
                  @click.native="showShipmentItemProgressModel(CANCEL)"
                >
                  Cancel Shipment
                </v-btn>
              </div>
              <v-card
                class="mx-auto mt-4"
                color="#fbfbfb"
              >
                <v-card-text>
                  <div>Order Ref#{{ shipment.shipment_id }}</div>
                  <v-row no-gutters>
                    <v-col
                      md="10"
                      xs="12"
                      class="d-flex align-center justify-start"
                    > 
                      <span class=" text-h4 text--primary align-center"> {{ cardHeader }} </span>
                    </v-col>
                    <v-col
                      md="2"
                      xs="12"
                      class="d-flex justify-end"
                    > 
                      <v-chip
                        class="ma-2"
                        color="green"
                        label
                        text-color="white"
                        large
                        justify="end"
                        style="min-width: 109px"
                      >
                        <v-icon left>
                          timeline
                        </v-icon>
                        {{ shipment.latest_status }}
                      </v-chip>
                    </v-col>
                  </v-row>

                  <v-divider />

                  <v-row
                    class="mt-4"
                    no-gutters
                  >
                    <v-col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="4"
                      class="d-flex justify-center"
                    >
                      <v-card
                        class="ma-2"
                        style="width: 100%"
                        max-width="600"
                        min-width="250"
                        color="#f2f2f2"
                      >
                        <v-card-text>
                          <p class="text-h6 text--primary">
                            <v-icon>event</v-icon>
                            Key Dates
                          </p>
                          <div class="text--primary my-1">
                            <v-icon left>
                              date_range
                            </v-icon>
                            <b>Date Expected:</b> {{ shipment.ExpectedDeliveryDate ? new Date(shipment.ExpectedDeliveryDate).toLocaleDateString('en-GB') : '' }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              event
                            </v-icon>
                            <b>POD:</b> {{ shipment.POD_deliveredDate ? `${new Date(shipment.POD_deliveredDate).toLocaleDateString('en-GB')} ${new Date(shipment.POD_deliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : '' }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              event
                            </v-icon>
                            <b>Received Date (Loc):</b> {{ shipment.DeliveredDate ? `${new Date(shipment.DeliveredDate).toLocaleDateString('en-GB')} ${new Date(shipment.DeliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : '' }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              task_alt
                            </v-icon>
                            <b>Collection Date:</b> {{ shipment.CollectionDate ? `${new Date(shipment.CollectionDate).toLocaleDateString('en-GB')} ${new Date(shipment.CollectionDate).toLocaleTimeString('en-GB').substring(0,5)}` : '' }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              timeline
                            </v-icon>
                            <b>Current Status:</b> {{ shipment.status }}
                          </div>
                          <!-- <div class="text--primary my-1">
                            <v-icon left>
                              today
                            </v-icon>
                            <b>Status Date:</b> {{ shipment.write_timestamp ? new Date(shipment.write_timestamp).toLocaleString() : '' }}
                          </div> -->
                          <div class="text--primary my-1">
                            <v-icon left>
                              account_circle
                            </v-icon>
                            <b>Last Status Change:</b> {{ shipment.LastModifiedBy }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="4"
                      class="d-flex justify-center"
                    >
                      <v-card
                        class="ma-2"
                        style="width: 100%"
                        max-width="600"
                        min-width="250"
                        color="#f2f2f2"
                      >
                        <v-card-text>
                          <p class="text-h6 text--primary">
                            <v-icon>
                              inventory
                            </v-icon>
                            Shipment Information
                          </p>
                          <div class="text--primary my-1">
                            <v-icon left>
                              person
                            </v-icon>
                            <b>Collection Name:</b> {{ shipment.CollectionName }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              inventory
                            </v-icon>
                            <b>Customer Order Ref:</b> {{ shipment.CustomerOrderRef }} 
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              inventory
                            </v-icon>
                            <b>Description:</b> {{ shipment.description }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="4"
                      class="d-flex justify-center"
                    >
                      <v-card
                        class="ma-2"
                        style="width: 100%"
                        max-width="600"
                        min-width="250"
                        color="#f2f2f2"
                      >
                        <v-card-text>
                          <p class="text-h6 text--primary">
                            <v-icon>
                              assignment
                            </v-icon>
                            Additional Info
                          </p>
                          <div class="text--primary my-1">
                            <v-icon left>
                              timeline
                            </v-icon>
                            <b>Supplier:</b> {{ shipment.supplier_name }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              today
                            </v-icon>
                            <b>Location:</b> {{ shipment.location_business_name }}
                          </div>
                          <div class="text--primary my-1">
                            <v-icon left>
                              person_add
                            </v-icon>
                            <b>Collection Name Addnl:</b> {{ shipment.CollectionNameAddnl }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="headers"
                    :items="orderItems"
                    :search="search"
                    loading-text="Loading... Please wait"
                    class="elevation-2 mt-3 ml-1 mr-1"
                    style="background-color: #f2f2f2;"
                  >
                    <template #top>
                      <v-toolbar
                        flat
                        style="background-color: #f2f2f2;"
                      >
                        <v-toolbar-title><v-icon>inventory</v-icon>Order Items</v-toolbar-title>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card
                class="d-flex justify-start mb-6 mt-6"
                color="#fbfbfb"
              >
                <v-card-text>
                  <p class="text-h6 text--primary">
                    <v-icon>
                      inventory
                    </v-icon>
                    Supplier Delivery Note
                  </p>
                  <div class="text--primary my-1">
                    {{ shipment.SupplierDeliveryNote }}
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                class="d-flex justify-start mb-6"
                color="#fbfbfb"
              >
                <v-card-text>
                  <p class="text-h6 text--primary">
                    <v-icon>
                      inventory
                    </v-icon>
                    Receive / Collection Details
                  </p>
                  <div class="text--primary my-1">
                    {{ shipment.CustomerPickupProof }}
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                class="d-flex justify-start mb-6"
                color="#fbfbfb"
              >
                <v-card-text>
                  <p class="text-h6 text--primary">
                    <v-icon>
                      folder
                    </v-icon>
                    Additional Detail ({{ shipment.evidenceExists ? shipment.evidenceExists : 0 }} items)
                  </p>
                  <div class="text--primary my-1" />
                </v-card-text>
              </v-card>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <ProgressShipmentItemModal
      :show="showProgressModal"
      :shipment="shipment"
      :shipment-items="shipmentItemsToProgress"
      :mode="mode"
      @close="close"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShipmentFromId } from '../api/api.service';
import { LOCATION, COLLECTED, CANCELLED, SHIPMENT_ITEM_HEADERS, shipmentStatus, CREATED } from '../static';
import { ProgressShipmentItemModal } from '@/components';
import { COLLECTION, DELIVERY, CANCEL, ADMIN, SUPPLIER } from '../static/constants';

export default {
  name: 'ShipmentInformation',
  components: {
    ProgressShipmentItemModal
  },
  props: {
    dataBackgroundColor: {
      type: String,
      default: ''
    },
    shipmentTab: { 
      type: Number,
      default: 0 
    }
  },
  data () {
    return {
      shipment: null,
      loading: true,
      headers: SHIPMENT_ITEM_HEADERS,
      orderItems: [],
      search: '',
      LOCATION: LOCATION,
      CREATED: CREATED,
      COLLECTED: COLLECTED,
      CANCELLED: CANCELLED,
      CANCEL: CANCEL,
      shipmentStatus: shipmentStatus,
      showProgressModal: false,
      shipmentItemsToProgress: [],
      mode: null,
      COLLECTION: 'Collection',
      DELIVERERY: 'Delivery'
    };
  },
  computed: {
    ...mapGetters([ 'user' ]),
    cardHeader () {
      let header = '';
      header = this.shipment.SupplierOrderNo ? this.shipment.SupplierOrderNo : header;
      header = this.shipment.CustomerOrderRef ? header.length === 0 ? this.shipment.CustomerOrderRef : `${header} - ${this.shipment.CustomerOrderRef}` : header;
      header = this.shipment.CustomerPickupPIN ? header.length === 0 ? this.shipment.CustomerPickupPIN : `${header} - PIN:${this.shipment.CustomerPickupPIN}` : header;
      return header;
    },
    showAcceptDelivery() {
      return this.orderItems.some(item => !item.DeliveredDate && item.shipment_item_StatusCode !== 'CAN');
    },
    showAcceptCollection() {
      return this.orderItems.some(item => item.DeliveredDate && !item.CollectedDate);
    },
    showCancelShipment () {
      return this.orderItems.some(item => !item.DeliveredDate && item.shipment_item_StatusCode !== 'CAN') && (this.user?.userType === ADMIN || this.user?.userType === SUPPLIER);
    }
  },
  watch: {
    mode(newVal) {
      if (newVal === DELIVERY || newVal === CANCEL) {
        this.shipmentItemsToProgress = this.orderItems.filter(item => !item.DeliveredDate);
      } else if (newVal === COLLECTION) {
        this.shipmentItemsToProgress = this.orderItems.filter(item => item.DeliveredDate && !item.CollectedDate);
      } else {
        this.shipmentItemsToProgress = [];
      }
    }
  },
  async created () {
    this.loading = true;
    const response = await getShipmentFromId(this.$store, this.$route.params.id);
    this.shipment = { ...response.shipment, update_user: response.update_user };
    this.orderItems = this.formatShipmentItems(response.items);
    this.loading = false;
  },
  methods: {
    goBack() {
      if (this.shipmentTab === -1 ) {
        this.$router.push({ name: 'Find Shipment' });
      } else {
        this.$router.push({ name: 'Shipments', params: { shipmentTab: this.shipmentTab }});
      }
    },
    showShipmentItemProgressModel(mode) {
      this.mode = mode;
      this.showProgressModal = true;
    },
    close(updatedShipment) {
      if (updatedShipment) {
        this.shipment = { ...updatedShipment.shipment, update_user: updatedShipment.update_user };
        this.orderItems = this.formatShipmentItems(updatedShipment.items);
        this.mode = null;
      }
      this.showProgressModal = false;
    },
    formatShipmentItems(items) {
      return items.map(item => {
        return {
          ...item,
          ...this.calcShipmentItemStatus(item)
        };
      }); 
    },
    calcShipmentItemStatus(item) {
      if (item.shipment_item_StatusCode === 'CAN') {
        return {
          status: `${item.shipment_item_StatusCode} ${ item.modifiedDate ? new Date(item.modifiedDate).toLocaleDateString(): ''}`,
          pod: item.POD_deliveredDate ? `${new Date(item.POD_deliveredDate).toLocaleDateString()}  ${new Date(item.POD_deliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : ''
        }; 
      } else if (!item.DeliveredDate && !item.CollectedDate) {
        return {
          status: `${item.shipment_item_StatusCode} ${item.ExpectedDeliveryDate ? new Date(item.ExpectedDeliveryDate).toLocaleDateString() : ''}`,
          pod: item.POD_deliveredDate ? `${new Date(item.POD_deliveredDate).toLocaleDateString()}  ${new Date(item.POD_deliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : ''
        };
      } else if (item.DeliveredDate && !item.CollectedDate) {
        return {
          status: `${item.shipment_item_StatusCode} ${item.DeliveredDate ? new Date(item.DeliveredDate).toLocaleDateString() : ''}`,
          pod: item.POD_deliveredDate ? `${new Date(item.POD_deliveredDate).toLocaleDateString()}  ${new Date(item.POD_deliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : ''
        };
      } else if (item.DeliveredDate && item.CollectedDate) {
        return {
          status: `${item.shipment_item_StatusCode} ${item.CollectedDate ? new Date(item.CollectedDate).toLocaleDateString() : ''}`,
          pod: item.POD_deliveredDate ? `${new Date(item.POD_deliveredDate).toLocaleDateString()}  ${new Date(item.POD_deliveredDate).toLocaleTimeString('en-GB').substring(0,5)}` : ''
        };
      }
      return {};
    }
  }
};
</script>
<style scoped>
    .flex-justify-content-center {
        display: flex;
        justify-content: space-between;
    }
    .pa-2 {
        padding: 2rem;
    }
</style>
