import Vue from 'vue';
import { logout, refresh } from '../firebase/firebase';
import { ADMIN, SUPPLIER, LOCATION } from '../static/constants';

const baseUrl = 'https://backend-dev-liqxexwt4q-nw.a.run.app';
// const baseUrl = 'http://localhost:8090';

const checkToken = async (count = 0) => {
  if (count > 10) {
    logout();
  }
  if (Vue.$cookies.get('accessToken')) return true;
  if(!Vue.$cookies.get('accessToken') && Vue.$cookies.get('refreshToken')) {
    await refresh();
    await new Promise(r => setTimeout(r, 1000));
    return checkToken(count + 1);
  }
  return false; 
};

const getHeaders = async (store) => {
  if (await checkToken()) {
    if (!store.getters.user || !store.getters.user.userType) {
      const user = await getUserDataFromToken();
      await store.dispatch('GET_USER', {
        userType: user.user_type,
        statusCode: user.user_status_code
      });     
    }
    return {
      Authorization: Vue.$cookies.get('accessToken'),
      userType: store.getters.user.userType
    };
  } else {
    return false;
  }
};

export const getLoginDetails = async (username) => {
  const apiKey = `l3ts-t4lk-ab0ut-6-${new Date().toISOString().substring(0, 10)}`;
  const response = await Vue.axios.post(baseUrl + `/user/get-email?apiKey=${apiKey}`, {username});
  return response.data;
};

export const getUserDataFromToken = async () => {
  await checkToken();
  const headers = {
    Authorization: Vue.$cookies.get('accessToken')
  };
  const response = await Vue.axios.get(baseUrl + '/user/from-token', { headers });
  return response.data;
};

export const getShipmentFromId = async (store, id) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + `/shipment/${id}`, { headers });
  return response.data;
};

export const getShipmentsSearchTerm = async (store, term) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + `/shipment/search/${term}`, { headers });
  return response.data;
};

export const getShipmentsForUser = async (store, status, supplier, location, start, end) => {
  const headers = await getHeaders(store);
  let queryString = '';
  queryString = status ? queryString += `?status=${status}` : queryString;
  queryString = supplier ? queryString += `${queryString.length > 0 ? '&' : '?' }supplier=${supplier}` : queryString;
  queryString = location ? queryString += `${queryString.length > 0 ? '&' : '?' }location=${location}` : queryString;
  queryString = start ? queryString += `${queryString.length > 0 ? '&' : '?' }start=${start}` : queryString;
  queryString = end ? end += `${queryString.length > 0 ? '&' : '?' }end=${end}` : queryString;
  const response = await Vue.axios.get(baseUrl + `/shipment/get/my${queryString}`, { headers });
  return response.data;
};

const getStatType = userType => {
  switch(userType) {
  case LOCATION:
    return 'location';
  case SUPPLIER: 
    return 'supplier';
  case ADMIN: 
    return 'admin';
  default:
    return false;
  }
};

export const getStats = async(store, userType, ids) => {
  const headers = await getHeaders(store);
  const type = getStatType(userType);
  const response = await Vue.axios.get(baseUrl + `/stats/${type}/${ids}`, { headers });
  return response.data; 
};

export const getUsers = async (store) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + '/user/get-users', { headers });
  return response.data;
};

export const getUser = async (store, id) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + `/user/id/${id}`, { headers });
  return response.data;
};

export const getLocations = async (store) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + '/location/get-locations', { headers });
  return response.data;
};

export const getSuppliers = async (store) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + '/supplier/get-suppliers', { headers });
  return response.data;
};

export const acceptDelivery = async (store, shipmentId, shipmentItems, note) => {
  const headers = await getHeaders(store);
  const body = { shipmentItems, note };
  const response = await Vue.axios.put(baseUrl + `/shipment/${shipmentId}/delivery`, body, { headers });
  return response;
};

export const acceptCollection = async (store, shipmentId, shipmentItems, note) => {
  const headers = await getHeaders(store);
  const body = { shipmentItems, note };
  const response = await Vue.axios.put(baseUrl + `/shipment/${shipmentId}/collection`, body, { headers });
  return response;
};

export const cancelShipmentItems = async (store, shipmentId, shipmentItems, note) => {
  const headers = await getHeaders(store);
  const body = { shipmentItems, note };
  const response = await Vue.axios.put(baseUrl + `/shipment/${shipmentId}/cancel`, body, { headers });
  return response;
};

export const createUser = async (store, user) => {
  const headers = await getHeaders(store);
  const body = {...user};
  const response = await Vue.axios.post(baseUrl + '/user/create', body, { headers });
  return response;
};

export const getUserInfo = async (store) => {
  const headers = await getHeaders(store);
  const response = await Vue.axios.get(baseUrl + '/user/info', { headers });
  return response.data;
};
