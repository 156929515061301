import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc
} from 'firebase/firestore';
import { getUserDataFromToken, getLoginDetails } from '../api/api.service';
const CryptoJS = require('crypto-js');

const firebaseConfig = {
  apiKey: 'AIzaSyASLgGLYE1UCKwESDig3ZD9nJTJCIT5KD0',
  authDomain: 'space-location.firebaseapp.com',
  projectId: 'space-location',
  storageBucket: 'space-location.appspot.com',
  messagingSenderId: '635912868061',
  appId: '1:635912868061:web:9e3e96ab83ea89be8c0b34',
  measurementId: 'G-GZ78KV13S7'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const decrypt = (email) => {
  const bytes  = CryptoJS.AES.decrypt(email, 'lfclfclfc');
  return bytes.toString(CryptoJS.enc.Utf8);
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    if (!validateEmail(email)) {
      const response = await getLoginDetails(email);
      email = await decrypt(response.login);
    }
    const signInResponse = await signInWithEmailAndPassword(auth, email, password);
    Vue.$cookies.set('accessToken', signInResponse.user.accessToken, '55min');
    Vue.$cookies.set('refreshToken', signInResponse.user.stsTokenManager.refreshToken, '8h');
    const user = await getUserDataFromToken();
    return {
      ...signInResponse.user,
      userType: user.user_type,
      statusCode: user.user_status_code,
      primarySupplierId: user.primary_supplier_id,
      primaryLocationId: user.primary_location_id
    };
  } catch (err) {
    console.error(err);
    return { error: err.code };
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
  Vue.$cookies.remove('accessToken');
  Vue.$cookies.remove('refreshToken');
  localStorage.removeItem('userInfo');
  location.reload();
};

const refresh = async () => {
  try {
    const token = await auth.currentUser.getIdToken(true); // here we force a refresh
    Vue.$cookies.set('accessToken', token, '55min');
  } catch (err) {
    console.error('Error Refreshing Token: ', err);
  }
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  refresh
};
