<template>
  <div class="content">
    <div
      v-if="loading"
      class="md-layout justify-center"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      />
    </div>
    <div
      v-else
      class="md-layout"
    > 
      <div
        v-for="(card, index) in stats"
        :key="index"
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="green">
          <template #header>
            <md-icon>inventory</md-icon>
          </template>

          <template #content>
            <p class="category">
              {{ card.StatName }}
            </p>
            <h3 class="title">
              {{ card.StatValue }}
            </h3>
          </template>

          <template #footer>
            <div
              v-if="card.SubStatName"
              class="stats"
            >
              <md-icon>inventory</md-icon>
              {{ card.SubStatName }} - {{ card.SubStatValue }}
            </div>
          </template>
        </stats-card> 
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStats } from '../api/api.service';
import { checkUser } from '../service/service';
import { ADMIN, SUPPLIER, LOCATION } from '../static/constants';
import {
  StatsCard
} from '@/components';
import moment from 'moment';


export default {
  name: 'AppDashboard',
  components: {
    StatsCard
  },
  data () {
    return {
      loading: false,
      stats: []
    };
  },
  computed: {
    ...mapGetters([ 'user' ])
  },
  async created () {
    this.loading = true;
    if (this.needsStatsUpdate()) {
      const user = await checkUser(this.user, this.$store, this.$router);
      const stats = await getStats(this.$store, user.user_type ? user.user_type : user.userType, this.getStatId(user));
      sessionStorage.setItem('stats', JSON.stringify({ exp: moment().add(1, 'hours'), stats }));
      this.stats = stats;
    } else {
      this.stats = JSON.parse(sessionStorage.getItem('stats')).stats;
    }
    this.loading = false;
  },
  methods: {
    getStatId(user) {
      const type = user.user_type ? user.user_type : user.userType;
      switch(type) {
      case LOCATION:
        return user.primaryLocationId;
      case SUPPLIER: 
        return user.primarySupplierId;
      case ADMIN: 
        return 0;
      default:
        return false;
      }
    },
    needsStatsUpdate() {
      const sessionStats = sessionStorage.getItem('stats');
      if (sessionStats) {
        const stats = JSON.parse(sessionStats);
        if (moment(stats.exp) < moment())
          sessionStorage.removeItem('stats');
        else 
          return false;
      } 
      return true;
    }
  }
};
</script>
