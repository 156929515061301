<template>
  <v-row justify="center">
    <v-dialog
      :value="show"
      persistent
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-card-title>
          <div class="user-modal-header">
            <span class="user-modal-header-title">Edit User</span> 
            <v-btn
              class="float-right"
              color="white"
              icon
              small
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text
          v-if="loading"
          class="pb-0"
        >
          <v-progress-circular
            v-if="loading"
            :size="70"
            :width="7"
            color="green"
            indeterminate
          />
        </v-card-text>
        <v-card-text
          v-else
          class="pb-0"
        >
          <v-container>
            <v-row justify="center">
              <v-col
                cols="12"
                xs="12"
                class="mt-0"
              >
                <v-text-field
                  v-model="user.user_id"
                  prepend-icon="mdi-card-account-details"
                  label="User ID"
                  type="text"
                  disabled
                />
                <v-text-field
                  v-model="user.login"
                  prepend-icon="mdi-login-variant"
                  label="Login / Username"
                  type="text"
                />
                <v-text-field
                  v-model="user.email"
                  prepend-icon="mdi-email"
                  label="Email Address"
                  type="text"
                />
                <v-select
                  :items="USER_TYPES"
                  item-text="name"
                  item-value="value"
                  :value="user.user_type"
                  prepend-icon="mdi-account"
                  label="User Type"
                />
                <v-select
                  :items="USER_STATUSES"
                  item-text="name"
                  item-value="value"
                  :value="user.user_status_code"
                  prepend-icon="mdi-account"
                  label="User Status"
                />
                <v-select
                  v-if="user.user_type === LOCATION"
                  :value="user.locations"
                  :items="locations"
                  item-text="name"
                  item-value="location_id"
                  attach
                  chips
                  label="Location Access"
                  prepend-icon="mdi-domain"
                  multiple
                />
                <v-select
                  v-if="user.user_type === SUPPLIER"
                  :value="user.suppliers"
                  :items="suppliers"
                  item-text="name"
                  item-value="supplier_id"
                  attach
                  chips
                  label="Supplier Access"
                  prepend-icon="mdi-domain"
                  multiple
                />
                <v-text-field
                  :value="Date(user.modifiedDate)"
                  prepend-icon="mdi-calendar-today"
                  label="Last Updated Date"
                  type="text"
                  disabled
                />
                <v-text-field
                  :value="Date(user.createdDate)"
                  prepend-icon="mdi-calendar"
                  label="Created Date"
                  type="text"
                  disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="updateUser"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { LOCATION, SUPPLIER, USER_TYPES, USER_STATUSES} from '@/static';
import { getUser } from '@/api/api.service';
import { getUserType } from '@/service/service';

export default {
  name: 'UserModal',
  props: {
    show: { type: Boolean, default: false },
    selectedUser: Object,
    suppliers: Array,
    locations: Array
  },
  data() {
    return {
      loading: true,
      user: null,
      LOCATION: LOCATION,
      SUPPLIER: SUPPLIER,
      USER_TYPES: USER_TYPES,
      USER_STATUSES: USER_STATUSES
    };
  },
  computed: {},
  watch: {
    async show(shouldShow) {
      if (shouldShow) {
        this.loading = true;
        this.user = await getUser(this.$store, this.selectedUser.user_id); 
        this.loading = false;
      }
    }
  },
  methods: {
    getUserType,
    close() {
      this.$emit('close');
    },
    updateUser() {
      console.log('this.user', this.user);
    }
  } 
};
</script>

<style scoped>
.user-modal-header {
    width: 100%;
    padding: 10px 15px;
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    background: linear-gradient(60deg, #66bb6a, #43a047);
    border-radius: 3px;
    color: white;
}
.user-modal-header-title{
    margin-top: 0;
    font-weight: 400 !important;
    margin-bottom: 5px;
}
</style>
