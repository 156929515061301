<template>
  <div class="content">
    <div class="md-layout">
      <md-card>
        <md-card-header data-background-color="green">
          <h4 class="title">
            Admin Panel
          </h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <v-tabs
              v-model="tab"
              class="d-flex justify-center full-width"
            >
              <v-tab>Users</v-tab>
              <v-tab>Create User</v-tab>
              <v-tab>Create Location</v-tab>
              <v-tab>Create Supplier</v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              style="width:100%"
            >
              <v-tab-item>
                <v-card flat>
                  <admin-users />
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text> 
                    <create-user mode="Create" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text> {{ tab }} </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text> {{ tab }} </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADMIN } from '@/static/constants';
import { AdminUsers, CreateUser } from '@/components';

export default {
  name: 'AdminPanel',
  components: { AdminUsers, CreateUser },
  data () {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters([ 'user' ])
  },
  created() {
    if (this.user.userType !== ADMIN) {
      this.$router.push('/dashboard');
    }
  }
};
</script>
<style scoped>

.full-width {
  width: 100%
}

.v-window {
  overflow: visible !important;
}

</style>
