<template>
  <v-row justify="center">
    <v-dialog
      :value="show"
      persistent
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-card-title>
          <div class="accept-delivery-modal-header">
            <span class="accept-delivery-modal-header-title">{{ mode }} - {{ shipment.barcode }} - {{ shipment.CustomerOrderRef }}  </span> 
            <v-btn
              class="float-right"
              color="white"
              icon
              small
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row justify="center">
              <v-col
                cols="12"
                xs="12"
                class="mt-0"
              >
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="shipmentItemOptions"
                  item-key="shipment_item_id"
                  show-select
                  class="elevation-2"
                  style="background-color: #f2f2f2;"
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                xs="12"
                class="pt-0"
              >
                <v-textarea
                  v-model="note"
                  filled
                  :label="`${mode} Note (vehicle reg, driver name, etc)`"
                  rows="2"
                  auto-grow
                />
                <v-checkbox
                  v-model="confirm"
                  class="justify-start mt-0 pt-0"
                  color="green"
                  :rules="[rules.required]"
                  :label="`I confirm the ${mode} of shipment item(s)`"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            :disabled="submitting"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="canSubmit"
            :loading="submitting"
            @click="submit"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { COLLECTION, DELIVERY, CANCEL } from '../../static/constants';
import { acceptDelivery, acceptCollection, cancelShipmentItems } from '../../api/api.service';

export default {
  name: 'ProgressShipmentItemModal',
  props: {
    show: { type: Boolean, default: false },
    shipmentItems: {type: Array, default: () => []},
    shipment: { type: Object, default: () => {}},
    mode: {type: String, default: null}
  },
  data: () => ({ 
    headers: [ 
      { text: 'Barcode', value: 'barcode' },
      { text: 'Supplier Ref', value: 'SupplierOrderItemRef' },
      { text: 'Current Status', value: 'status' },
      { text: 'Ref', value: 'itemRef' }
    ],
    note: '',
    confirm: false,
    rules: {
      required: v => !!v || 'This field is required'
    },
    submitting: false,
    shipmentItemOptions: [],
    selected: []
  }),
  computed: {
    canSubmit() {
      if (this.selected.length > 0) {
        return !this.confirm;
      }
      return true;
    }
  },
  watch: {
    show(newVal) {
      if (newVal === true) this.shipmentItemOptions = [ ...this.shipmentItems ];
    }
  },
  methods: {
    close() {
      this.selected = [];
      this.note = '';
      this.$emit('close');
    },
    async submit() {
      this.submitting = true;
      const items = this.selected.map(item => item.shipment_item_id).join(',');
      let response;
      try {
        if (this.mode === DELIVERY) {
          response = await acceptDelivery(this.$store, this.shipment.shipment_id, items, this.note);
        } else if (this.mode === COLLECTION) {
          response = await acceptCollection(this.$store, this.shipment.shipment_id, items, this.note);
        } else if (this.mode === CANCEL) {
          response = await cancelShipmentItems(this.$store, this.shipment.shipment_id, items, this.note);
        }
      } catch (err) {
        console.error(err);
        this.handleError();
        this.submitting = false;
      }
      if (response.status !== 200) {
        this.handleError();
      } else {
        this.$notify({
          message:
            'Shipment items successfully updated.',
          icon: 'fact_check',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
        this.shipmentItemOptions = this.shipmentItemOptions.filter(item => !item.selected);
        this.submitting = false;
        this.confirm = false;
        this.note = '';
        this.selected = [];
        this.$emit('close', response.data);
      }
    },
    handleError() {
      this.$notify({
        message:
            'Error updating shipment items, please try again.',
        icon: 'add_alert',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger'
      });
    }
  } 
};
</script>

<style scoped>
.accept-delivery-modal-header {
    width: 100%;
    padding: 10px 15px;
    -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    background: linear-gradient(60deg, #66bb6a, #43a047);
    border-radius: 3px;
    color: white;
}
.accept-delivery-modal-header-title{
    margin-top: 0;
    font-weight: 400 !important;
    margin-bottom: 5px;
}
.mdi-checkbox-marked::before {
  color: #66bb6a !important
}
</style>
