<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">
              Shipments
            </h4>
            <p class="category">
              Use the dropdowns below to filter results
            </p>
          </md-card-header>
          <md-card-content>
            <v-container>
              <v-row>
                <v-col
                  class="d-flex justify-start align-end"
                  sm="12"
                  md="2"
                >
                  <v-select
                    v-model="selectedView"
                    :items="views"                
                    label="Shipment Table View"
                    hide-details
                    dense
                  />
                </v-col>

                <v-col
                  sm="12"
                >
                  <v-tabs
                    v-model="tab"
                    class="d-flex justify-center"
                    grow
                  >
                    <v-tab>Shipments Due</v-tab>
                    <v-tab>Awaiting Collection</v-tab>
                    <v-tab>History</v-tab>
                  </v-tabs>
                </v-col>

                <v-col
                  v-if="false"
                  class="d-flex justify-end align-end"
                  sm="3"
                >
                  <v-btn
                    plain
                    tile
                    @click="toggleFilters"
                  >
                    Show Filters 
                    <v-icon :class="`${showFilters ? 'open' : ''}`">
                      mdi-chevron-up
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-expand-transition>
              <v-container v-show="showFilters">
                <v-row
                  no-gutters
                >
                  <v-col
                    sm="6"
                    md="3"
                  >
                    <v-select
                      :items="views"
                      label="Supplier"
                      hide-details
                      dense
                      class="mr-2 ml-2"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    md="3"
                  >
                    <v-select
                      :items="views"
                      label="Location"
                      hide-details
                      dense
                      class="mr-2 ml-2"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    md="3"
                  >
                    <v-select
                      :items="views"
                      label="Status"
                      hide-details
                      dense
                      class="mr-2 ml-2"
                    />
                  </v-col>
                  <v-col
                    sm="6"
                    md="3"
                    align="center"
                  >
                    <v-btn
                      class="mr-2 ml-2 text-center search-btn"
                    >
                      Search 
                      <v-icon>
                        mdi-search
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>

            <v-card class="mt-2">
              <v-card-title class="table-header">
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  style="max-width: 250px"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="shipments"
                :search="search"
                :loading="loadingShipments"
                loading-text="Loading... Please wait"
                @click:row="goToShipment"
              >
                <template #[`item.selectDate`]="{ item }">
                  <v-chip
                    :color="getColor(item.selectDate)"
                    dark
                  >
                    {{ item.selectDate }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SUPPLIER, LOCATION, supplierView, locationView  } from '../static';
import { getShipmentsForUser } from '../api/api.service';
import moment from 'moment';
import { checkUser, getShipmentTableHeaders } from '../service/service';

const DUE = 0;
const DELIVERED = 1;
const COLLECTED = 2;

const SHIPMENT_MAP = {
  0: 'Expected',
  1: 'Delivered',
  2: 'Collected' 
};
 
export default {
  name: 'MyShipments',
  props: {
    shipmentTab: Number
  },
  data: () => ({
    tab: null,
    selectedView: '',
    views: [ supplierView, locationView ],
    showFilters: false,
    search: '',
    shipments: [],
    loadingShipments: false
  }),
  computed: {
    ...mapGetters([ 'user' ]),
    headers: function() {
      return this.getHeaders();
    }
  },
  watch: {
    selectedView(newValue) {
      localStorage.setItem('defaultView', newValue);
    },
    async tab(newValue) {
      await checkUser(this.user, this.$store, this.$router);
      this.getStatusTypesFromTab(newValue);
    }
  },
  async created() {
    this.selectedView = this.getDefaultView();
    if (!localStorage.getItem('defaultView')) {
      localStorage.setItem('defaultView', this.selectedView);
    }
    this.tab = this.shipmentTab ? this.shipmentTab : 0;
  },
  methods: {
    getHeaders() {
      return getShipmentTableHeaders(this.selectedView, SHIPMENT_MAP[this.tab]);
    },
    toggleFilters () {
      this.showFilters = !this.showFilters;
    },
    getDefaultView() {
      if (localStorage.getItem('defaultView')) {
        return localStorage.getItem('defaultView');
      } 
      if (this.user.userType === LOCATION) return 'Location';
      if (this.user.userType === SUPPLIER) return 'Supplier';
      return 'Supplier';
    },
    getStatusTypesFromTab(tab) {
      if (tab === DUE) {
        this.fetchShipments('due');
      } else if (tab === DELIVERED) {
        this.fetchShipments('delivered');
      } else if (tab === COLLECTED) {
        this.fetchShipments('collected');
      }
    },
    async fetchShipments(shipmentTypes) {
      this.loadingShipments = true;
      try {
        const shipmentsResponse = await getShipmentsForUser(this.$store, shipmentTypes);
        const shipments = shipmentsResponse[0];
        if (shipments) {
          this.formatShipments(shipments);
          this.shipments = shipments;
        }
      } catch (err) {
        console.error(err);
      }
      this.loadingShipments = false;
    },
    formatShipments(shipments) {
      shipments.forEach(shipment => {
        const date = shipment.selectDate;
        shipment.selectDate = moment(date).format('DD-MM-YYYY');
        shipment.searchDate = moment(date).format('YYYY-MM-DD');
      });
    },
    goToShipment(shipment) {
      this.$router.push({ name: 'Shipment', params: { id: shipment.shipment_id, shipmentTab: this.tab }});
    },
    getColor(dueDate) {
      if (this.tab === 2) return 'green'; 
      return moment(dueDate, 'DD-MM-YYYY').isBefore(moment().startOf('day')) ? 'red' : 'green';
    } 
  }
};
</script>

<style scoped>
  .open {
    transform: rotate(-180deg);
  }
  .container {
    padding-bottom: 1px;
  }
  .table-header {
    padding-top: 0;
    padding-bottom: 0;
  }
  
</style>
