import Vue from 'vue';
import App from './App';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';

// router setup
import router from './routes/routes';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import Notifications from './components/NotificationPlugin';

// MaterialDashboard plugin
import MaterialDashboard from './material-dashboard';

// Store 
import store from './store';

import Chartist from 'chartist';
import vuetify from './plugins/vuetify';

Vue.prototype.$Chartist = Chartist;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(store);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  data: function () {
    return {
      Chartist: Chartist
    };
  },
  render: (h) => h(App),
  router,
  vuetify,
  store: store
});
