import { getUserDataFromToken } from '../api/api.service';
import { supplierView, locationView, supplierHeaders, locationHeaders, USER_TYPE } from '../static';

export const checkUser = async (user, store, router) => {
  if (!user) {
    try {
      const user = await getUserDataFromToken();
      store.dispatch('GET_USER', user);
      return user;
    } catch (err) {
      console.error();
      router.push('/login');
    }
  } 
  return user;
};

export const getShipmentTableHeaders = (headerType, status) => {
  if (headerType === supplierView) return supplierHeaders(status);
  if (headerType === locationView) return locationHeaders(status);
  return supplierHeaders(status);
};

export const getUserType = (userType) => {
  return USER_TYPE[userType];
};
